import axios from 'axios';
import { getCookie, removeCookie, setCookie } from './cookie';
import { getRefreshToken } from './getRefreshToken';
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const LOGOUT_URL = `auth/logout`;

export type ApiErrorData = {
  code: number;
  message: string;
  data: any;
  status?: number;
};

/**----------------------------- axios 인스턴스 ---------------------------- */

// 초기 interceptor 값
export const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'content-type': 'application/json;charset=UTF-8',
    accept: 'application/json',
  },
  withCredentials: true,
});

export const tokenUtils = {
  isLoggingOut: false,

  async logout() {
    if (this.isLoggingOut) {
      return;
    }

    this.isLoggingOut = true;

    try {
      removeCookie('accessToken');
      delete instance.defaults.headers.common['Authorization'];
      await instance.post(LOGOUT_URL).catch(() => {});
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      this.isLoggingOut = false;
      window.location.href = '/login';
    }
  },

  async handleTokenExpiration() {
    await this.logout();
  },
};

// ============================= request interceptor ===================================
instance.interceptors.request.use(async (config) => {
  if (!config.headers) return config;

  // access token만 사용
  const token = getCookie('accessToken');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // baseURL 관련 data 처리는 유지
  if (config.baseURL === BASE_URL && config.data) {
    config.data = { ...config.data };
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    const newToken = response.headers['new-access-token'];
    if (newToken) {
      setCookie('accessToken', newToken, 60 * 60 * 24); // 2시간
      instance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    }
    return response;
  },
  async (err) => {
    if (err.response?.status === 401) {
      const newToken = err.response.headers['new-access-token'];
      if (newToken) {
        setCookie('accessToken', newToken, 60 * 60 * 24);
        instance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

        const originalRequest = err.config;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return instance(originalRequest);
      }

      await tokenUtils.handleTokenExpiration();
      return Promise.reject(err.response.data);
    }
    return Promise.reject(err.response.data || err);
  },
);
// -------------

/* ----------------------------- api 호출 ---------------------------- */

interface ApiProps {
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
  url: string;
  data?: any;
  params?: any;
}
interface GetApiProps {
  url: string;
  params?: any;
}

export const api = async (apiInfo: ApiProps): Promise<any> => {
  const { method, url, data, params } = apiInfo;
  console.log('body', data);
  return instance({
    method,
    url,
    data,
    params,
  }).then((res) => res.data);
};

export const getApi = async (apiInfo: GetApiProps): Promise<any> => {
  const { url, params } = apiInfo;
  return instance({
    method: 'GET',
    url,
    params,
  }).then((res) => res.data);
};

export interface MulterProps {
  url: string;
  formData: File;
  method: 'PATCH' | 'POST';
}

export async function multerApi({ url, formData, method }: { url: string; formData: File; method: 'PATCH' | 'POST' }): Promise<any> {
  const token = getCookie('accessToken');
  return axios({
    method: method,
    url: `${process.env.NEXT_PUBLIC_BASE_URL}${url}`,
    data: { file: formData },
    headers: {
      'content-type': 'multipart/form-data; charset=EUC-KR',
      accept: '*/*',
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  }).then((res) => res.data);
}

export async function multerFormApi({ url, formData, method }: { url: string; formData: FormData; method: 'PATCH' | 'POST' }): Promise<any> {
  const token = getCookie('accessToken');
  return axios({
    method: method,
    url: `${process.env.NEXT_PUBLIC_BASE_URL}${url}`,
    data: formData,
    headers: {
      'content-type': 'multipart/form-data; charset=EUC-KR',
      accept: '*/*',
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  }).then((res) => res.data);
}

export const snsWebLogin = async (sns: 'kakao' | 'google' | 'naver' | 'apple', data: { code: string; state?: string }) => {
  const res = await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/auth/sign-in/${sns}`, data);
  const { accessToken, refreshToken } = res.data.data;
  setCookie('accessToken', accessToken, 60 * 60 * 72);
  setCookie('refreshToken', refreshToken, 60 * 60 * 72);
  if ((global as any).ReactNativeWebView) {
    (global as any).ReactNativeWebView.postMessage(
      JSON.stringify( {data:{
        type: 'snsWebLogin',
        accessToken: accessToken,
        refreshToken: refreshToken
      }} )
    );
  } 
  return res.data.data.newUser;
};
