import { api, ApiErrorData } from '@/api/axios';
import { useMutation } from '@tanstack/react-query';

interface PostLikeRes {
  code: number;
  message: string;
  data: {
    customerProductLikeIdx: string;
    isLike: boolean;
  };
}

interface Param {
  productIdx: string;
}

export const usePostLikeItem = () => {
  const { mutate } = useMutation<PostLikeRes, ApiErrorData, Param>({
    mutationFn: (param) =>
      api({
        method: 'POST',
        url: `products/like?productIdx=${param.productIdx}`,
      }),
  });

  return { postLike: mutate };
};
