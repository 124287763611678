import { theme } from '@/app/styles/theme';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';

type Props = {
    content: string;
    BtnText?: string;
    cancelText?: string;
    onClickBtn: () => void;
    onClickCancel: () => void;
};

export default function ModalTwoBtns({ content, BtnText = '확인', cancelText = '취소', onClickBtn, onClickCancel }: Props) {
    //스크롤 방지
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <Modal>
            <Content>{content}</Content>
            <ButtonBox>
                <button onClick={onClickBtn}>{BtnText}</button>
                <button onClick={onClickCancel}>{cancelText}</button>
            </ButtonBox>
        </Modal>
    );
}

const Modal = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 360px;
    background: white;
    position: relative;

    @media screen and (max-width: 1024px) {
        width: 280px;
    }
`;

const Content = styled.p`
    display: flex;
    align-items: center;
    white-space: pre-line;
    /* height: 112px;
  max-height: 600px; */
    max-width: 90%;
    text-align: center;
    padding: 32px 0;
    min-height: 112px;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #404040;
`;
const ButtonBox = styled.div`
    ${theme.display.flexRow};
    width: 100%;
    height: 48px;
    border-top: 1px solid #e0e0e0;
    > button {
        text-align: center;

        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        width: 50%;
        padding: 20px 0;
        &:nth-of-type(1) {
            background-color: white;
            color: black;
        }
        &:nth-of-type(2) {
            background-color: ${theme.colors.black};
            color: white;
        }
    }
`;
